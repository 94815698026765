import React , { useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';

type Props = {
  html?: any
  guardarEnBD: (data: string) => void;
}



const Tinymce: React.FC<Props> = ({html, guardarEnBD}) => {
    const editorRef = useRef<any>(null);

    const log = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            console.log(content);
            guardarEnBD(content);
        }
    };

    return (<>
        <Editor
            apiKey='ijsdg0zbilqgyzeo6h3k13tk975nqy2mcjkkdrtoin3ofn9e'
            onInit={(evt, editor:any) => editorRef.current = editor}
            initialValue={html.value}
            init={{
            height: 500,
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
            ],
            menubar: 'file edit view insert format tools table help',
            toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            image_title: true,
            images_upload_url: 'postAcceptor.php',
            automatic_uploads: false
            
            }}
        />
        <div className="my-2 d-flex align-items-end flex-row-reverse">
        <button className="btn btn-primary" onClick={log}>Cambiar</button>
        </div>
    </>)
}

export {Tinymce}
